<div class="notification-list">
  <button mat-raised-button color="primary" (click)="openAddNotification()">Adicionar Notificação</button>
  <table mat-table [dataSource]="notifications" class="mat-elevation-z8">

    <!-- Título -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Título </th>
      <td mat-cell *matCellDef="let element"> {{element.title}} </td>
    </ng-container>

    <!-- Recorrência -->
    <!-- <ng-container matColumnDef="recurrence">
      <th mat-header-cell *matHeaderCellDef> Recorrência </th>
      <td mat-cell *matCellDef="let element"> {{getRecurrenceLabel(element.recurrence)}} </td>
    </ng-container> -->

    <!-- Ações -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Ações </th>
      <td mat-cell *matCellDef="let element">
        <button mat-button (click)="editNotification(element)">Editar</button>
        <button mat-button color="warn" (click)="deleteNotification(element.id)">Excluir</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
  </table>
   <mat-paginator [length]="totalElements"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="[5, 10, 25, 50]"
                 (page)="onPageChange($event)">
  </mat-paginator>
</div>
