import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotificationService {
    constructor(http) {
        this.http = http;
        this.base_url = environment.apiUrl;
    }
    sendNotification(data) {
        return this.http.post(`${this.base_url}/api/notifications`, data);
    }
    getNotification(page, pageSize, recurrence) {
        const params = {
            page: page.toString(),
            size: pageSize.toString()
        };
        if (recurrence && recurrence !== '') {
            params.recurrence = recurrence;
        }
        return this.http.get(`${this.base_url}/api/notifications`, { params });
    }
    updateNotification(id, data) {
        return this.http.put(`${this.base_url}/${id}`, data);
    }
    deleteNotification(id) {
        return this.http.delete(`${this.base_url}/${id}`);
    }
}
NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.HttpClient)); }, token: NotificationService, providedIn: "root" });
