import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotificationDetalhesModule } from "../notification-detalhes/notification-detalhes.module";
import { MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatRadioModule, MatSelectModule, MatTableModule } from "@angular/material";
import { NotificationListComponent } from "./notification-list.component";

@NgModule({
  imports: [
    CommonModule,
    NotificationDetalhesModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTableModule,
  ],
  declarations: [NotificationListComponent],
  exports: [NotificationListComponent],
})
export class NotificationListModule {}
