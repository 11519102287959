import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicaoDetalhesComponent } from './licao-detalhes.component';
import { ComponentsModule } from '../../components.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatInputModule, MatIconModule, MatCardModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatDialogModule, MatProgressSpinnerModule, MatButtonToggleModule, MatTooltipModule, MatProgressBarModule, MatToolbarModule, MatExpansionModule, MatOptionModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMaskModule } from '@fagnerlima/ng-mask';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NgMaskModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatToolbarModule,
    ComponentsModule,
    CKEditorModule,
    MatExpansionModule,
    MatOptionModule,
  ],
  declarations: [LicaoDetalhesComponent],
  exports: [LicaoDetalhesComponent],
  entryComponents: [LicaoDetalhesComponent]
})
export class LicaoDetalhesModule { }
