import { Arquivo } from "./arquivo.model";
import { Quizz } from "./quizz.model";
import { Turma } from "./turma.model";
import { User } from "./user.model";

export class LicaoEntity {
  id: string = null;
  data: Date = null;
  turma: Turma = null;
  professor: User = null;
  tema: string = null;
  objetivo: string = null;
  conteudo: string = null;
  metodologia: string = null;
  experienciaPedagogica: string = null;
  imagens: Arquivo<any>[] = [];
  status: boolean = false;
  listaDeVideosId: string[] = [];

  quizz: Quizz = new Quizz();
}
export interface LicaoEntityResponse {
  id: string;
  data: Date;
  turma: Turma;
  professor: User;
  tema: string;
  objetivo: string;
  conteudo: string;
  metodologia: string;
  experienciaPedagogica: string;
  imagens: string[];
  status: boolean;
  listaDeVideosId: string[];
  quizz?: {
    active: boolean;
    startDate: string;
    endDate: string;
    questions: Array<{
      enunciado: string;
      alternativas: string[];
      correctIndex: number;
      points: number;
    }>;
  };
}
