import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatGridListModule, MatIconModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatSelectModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImagesLancamentoComponent } from './lancamento/images-lancamento/images-lancamento.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { LicaoImagensComponent } from './licao/licao-imagens/licao-imagens.component';
import { InputImageComponent } from './input-image/input-image.component';
import { DdMmYyyyDatePipe } from '../shared/dd-mm-yyyy-date.pipe';
import { NotificationListComponent } from './notification/notification-list/notification-list.component';
import { NotificationDetalhesComponent } from './notification/notification-detalhes/notification-detalhes.component';

import { LicaoBibliotecaComponent } from './licao/licao-biblioteca/licao-biblioteca.component';




const COMPONENTS = [
  PaginatorComponent,
  InputImageComponent,
  ImagesLancamentoComponent,
  LicaoImagensComponent,
  LicaoBibliotecaComponent,
  DdMmYyyyDatePipe,
]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatGridListModule,
  ],
  exports: [...COMPONENTS],
//Jaciara: ione (radioterapia), prima de brasília teresinha, João.
//Jakeline: Ednilton, Jakeline trabalho dobrado
//Letícia: tratamento dos dentes e saúde do sidney
// Pr. Jose: dona francisca, julieta ferida e os resultados de exame
//
})
export class ComponentsModule { }
