import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../../services/notification.service";
import { MatDialog, MatTableDataSource, PageEvent } from "@angular/material";
import { NotificationDetalhesComponent } from "../notification-detalhes/notification-detalhes.component";
import { Notification } from "../../../model/notification.model";

@Component({
  selector: "app-notification-list",
  templateUrl: "./notification-list.component.html",
  styleUrls: ["./notification-list.component.scss"],
})
export class NotificationListComponent implements OnInit {
  notifications = new MatTableDataSource<Notification>();
  displayColumns: string[] = ["title", "actions"];
  totalElements = 0;
  pageSize = 10;
  pageNumber = 0;
  filterRecurrence = "";
  isLoading = false;

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadNotifications();
  }

  loadNotifications() {
    this.isLoading = true;
    this.notificationService
      .getNotification(this.pageNumber, this.pageSize, this.filterRecurrence)
      .subscribe(
        (data) => {
          this.notifications = new MatTableDataSource(data.content);
          this.totalElements = data.totalElements;
          this.pageNumber = data.number;
          this.pageSize = data.size;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          console.error("Erro ao carregar notificações", error);
        }
      );
  }

  applyFilter() {
    this.pageNumber = 0;
    this.loadNotifications();
  }

  onPageChange(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadNotifications();
  }

  getRecurrenceLabel(recurrence: string): string {
    switch (recurrence) {
      case "once":
        return "Única Vez";
      case "weekly":
        return "Semanal";
      case "monthly":
        return "Mensal";
      case "yearly":
        return "Anual";
      default:
        return "Desconhecido";
    }
  }

  openAddNotification() {
    const dialogRef = this.dialog.open(NotificationDetalhesComponent, {
      width: "600px",
      data: { isEdit: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadNotifications();
      }
    });
  }

  editNotification(notification) {
    const dialogRef = this.dialog.open(NotificationDetalhesComponent, {
      width: "600px",
      data: { isEdit: true, notification },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadNotifications();
      }
    });
  }

  deleteNotification(id: string) {
    if (confirm("Tem certeza que deseja excluir esta notificação?")) {
      this.notificationService.deleteNotification(id).subscribe(
        () => {
          this.loadNotifications();
        },
        (error) => {
          console.error("Erro ao excluir notificação", error);
        }
      );
    }
  }
}
