<h2 mat-dialog-title><span>Nova Lição</span></h2>
<mat-progress-bar mode="buffer" *ngIf="carregando == true"></mat-progress-bar>
<div class="overlay" *ngIf="carregando">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<mat-dialog-content class="mat-typography">
  <form #f [formGroup]="form" #f="ngForm">
    <div class="row">
      <div class="form-group">
        <div class="col-sm-3">
          <mat-form-field
            appearance="outline"
            class="example-full-width"
            class="col-sm-12"
          >
            <input
              matInput
              placeholder="Data da Lição"
              id="data"
              formControlName="data"
              [matDatepicker]="data"
              readonly="true"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="data"
            ></mat-datepicker-toggle>
            <mat-datepicker touchUi #data></mat-datepicker>
            <mat-error>Data é obrigatória</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-4 loading-container">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="turma">Turma</mat-label>
            <mat-select
              placeholder="Turma"
              id="turma"
              formControlName="turma"
              required
              [compareWith]="compareTurma"
              (selectionChange)="onTurmaSelected($event.value)"
            >
              <mat-option *ngFor="let turma of turmas | async" [value]="turma">
                {{ turma?.nome }}
              </mat-option>
            </mat-select>
            <mat-error>Turma é obrigatório</mat-error>
          </mat-form-field>
          <mat-progress-spinner
            *ngIf="carregando"
            class="loading-spinner"
            mode="indeterminate"
          ></mat-progress-spinner>
        </div>

        <div class="col-sm-5">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="professor">Professor</mat-label>
            <mat-select
              placeholder="Professor"
              id="professor"
              formControlName="professor"
              required
              [compareWith]="compareProfessor"
            >
              <mat-option
                *ngFor="let professor of professores | async"
                [value]="professor"
              >
                {{ professor?.nome }}
              </mat-option>
            </mat-select>
            <mat-error>Professor(a) é obrigatório</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="tema">Tema</mat-label>
            <input
              matInput
              placeholder="Tema"
              id="tema"
              formControlName="tema"
              required
            />
            <mat-error>Tema é obrigatório</mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="objetivo">Objetivos:</mat-label>
            <input
              matInput
              placeholder="Objetivos gerais e específicos"
              id="objetivo"
              formControlName="objetivo"
              required
            />
            <mat-error>Objetivo é obrigatório</mat-error>
          </mat-form-field>
        </div>
        <div class="editor">
          <div class="col-sm-12">
            <mat-label for="conteudo">Conteúdo</mat-label>
            <ckeditor
              [config]="editorConfig"
              id="conteudo"
              style="height: 300px"
              formControlName="conteudo"
              formControlName="conteudo"
              [editor]="Editor"
              (ready)="onReady($event)"
              [(ngModel)]="editorContent"
            ></ckeditor>
            <mat-error *ngIf="form.get('conteudo').invalid"
              >O Conteúdo é obrigatório</mat-error
            >
          </div>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="metodologia">Metodologia</mat-label>
            <input
              matInput
              placeholder="Metodologia"
              id="metodologia"
              formControlName="metodologia"
              required
            />
            <mat-error>Metodologia é obrigatória</mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="fill" class="col-sm-12">
            <mat-label for="experienciaPedagogica"
              >Experiência Pedagógica</mat-label
            >
            <textarea
              matInput
              placeholder="Experiência pedagógica"
              id="experienciaPedagogica"
              formControlName="experienciaPedagogica"
              rows="5"
              cols="40"
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <app-licao-imagens
      formControlName="imagens"
      (onImageDeleted)="deleteImage($event)"
    >
    </app-licao-imagens>
    <!-- Nova Seção: Biblioteca de Imagens (para reutilização) -->
    <!-- <div class="biblioteca-imagens-section">
      <h3>Biblioteca de Imagens</h3>
      <app-licao-biblioteca (onImageSelected)="onImageSelected($event)"></app-licao-biblioteca>
    </div> -->

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Quizz (opcional) </mat-panel-title>
      </mat-expansion-panel-header>

      <!-- Campo Active -->
       <div formGroupName="quizz" style="padding: 16px;">
      <mat-checkbox
        formControlName="active">
        Quizz está ativo?
      </mat-checkbox>

      <!-- Data início e fim -->
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field appearance="outline">
            <mat-label>Data Início</mat-label>
            <input
              matInput

              [matDatepicker]="startDate"
              formControlName="startDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field appearance="outline">
            <mat-label>Data Fim</mat-label>
            <input
              matInput
              [matDatepicker]="endDate"
              formControlName="endDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="endDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <hr />

      <!-- 3 perguntas -->
        <div formArrayName="questions">
          <div *ngFor="let questionCtrl of questionsArray.controls; let i = index"
            [formGroupName]="i">
            <p>Pergunta #{{ i + 1 }}</p>
            <mat-form-field appearance="outline" class="col-sm-12">
              <mat-label>Enunciado</mat-label>
              <input
                matInput
                formControlName="enunciado"
                placeholder="Ex: Quem foi Jesus...?"
              />
            </mat-form-field>

            <!-- Alternativas -->
            <div formArrayName="alternativas">
              <div
                *ngFor="let altCtrl of getAlternativas(i).controls; let j = index"
              >
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label>Alternativa #{{ j + 1 }}</mat-label>
                  <input
                    matInput
                    [formControl]="altCtrl"
                    placeholder="Digite a alternativa"
                  />
                </mat-form-field>
              </div>
            </div>

            <mat-form-field appearance="outline" class="col-sm-6">
              <mat-label>Escolha a alternativa correta</mat-label>
              <mat-select formControlName="correctIndex">
                <mat-option
                *ngFor="let altCtrl of getAlternativas(i).controls; let j = index"
                  [value]="j">
                  Alternativa #{{ j + 1 }} - {{ altCtrl.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-sm-6">
              <mat-label>Points</mat-label>
              <input matInput formControlName="points" type="number" />
            </mat-form-field>
            <hr />
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-raised-button color="warn" (click)="salvar()" cdkFocusInitial>
    Salvar
  </button>
</mat-dialog-actions>
