import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LicaoEntity, LicaoEntityResponse } from '../model/licao_entity.model';

@Injectable({ providedIn: 'root' })
export class LicaoV1Service {
  private _onSave: EventEmitter<LicaoEntityResponse> = new EventEmitter<LicaoEntityResponse>()
  private base_url = environment.apiUrl
  constructor(private http: HttpClient) { }

  onSave() {
    return this._onSave
  }

  findById(id: string, professorId: string) {
    return this.findPage('', id, professorId).pipe(map(values => values && values.length > 0 ? values[0] : null))
  }

  findPage(text: string, id?: string, professorId?: string, turmaId?: string, page = 0, pageSize = 20) {
    const params = {}
    if (text) {
      params['text'] = text
    }
    if (id) {
      params['id'] = id
    }
    if (professorId) {
      params['professorId'] = professorId
    }
    if (turmaId) {
      params['turmaId'] = turmaId
    }
    if (page) {
      params['page'] = page
    }
    if (pageSize) {
      params['page-size'] = pageSize
    }

    return this.http.get<LicaoEntityResponse[]>(`${this.base_url}/api/licao/v1/page`, { params })
  }

  findPageCount(text: string, id?: string, professorId?: string, turmaId?: string, pageSize = 20) {
    const params = {}
    if (text) {
      params['text'] = text
    }
    if (id) {
      params['id'] = id
    }
    if (professorId) {
      params['professorId'] = professorId
    }
    if (turmaId) {
      params['turmaId'] = turmaId
    }

    if (pageSize) {
      params['page-size'] = pageSize
    }
    return this.http.get<number>(`${this.base_url}/api/licao/v1/page/count`, { params })
  }

  save(licaoEntity: LicaoEntity) {
    const formData = new FormData()
    formData.append('data', licaoEntity.data.toISOString())
    formData.append('turma', JSON.stringify(licaoEntity.turma))
    formData.append('professor', JSON.stringify(licaoEntity.professor))
    formData.append('tema', licaoEntity.tema)
    formData.append('objetivo', licaoEntity.objetivo)
    formData.append('conteudo', licaoEntity.conteudo)
    formData.append('metodologia', licaoEntity.metodologia)
    if (licaoEntity.experienciaPedagogica) {
      formData.append('experienciaPedagogica', licaoEntity.experienciaPedagogica)
    }

    //Quizz
    if (licaoEntity.quizz) {
      formData.append('quizz', JSON.stringify(licaoEntity.quizz))
    }
    if (licaoEntity.imagens && licaoEntity.imagens.length > 0) {
      const imagensUrl: string[] = []
      for (const imagem of licaoEntity.imagens) {
        if (typeof imagem.data === 'string') {
          imagensUrl.push(imagem.data)
        }
        if (imagem.data instanceof File) {
          formData.append('imagens', imagem.data)
        }
      }
      if (imagensUrl.length > 0) {
        formData.append('imagensUrl', JSON.stringify(imagensUrl))
      }
    }
    formData.append('status', licaoEntity.status.toString())
    if (licaoEntity.id) {
      return this.http.put<LicaoEntityResponse>(`${this.base_url}/api/licao/v1/${licaoEntity.id}`, formData).pipe(tap(objetoSavo => this._onSave.emit(objetoSavo)))
    } else {
      return this.http.post<LicaoEntityResponse>(`${this.base_url}/api/licao/v1`, formData)
      .pipe(tap(objetoSavo => {
        this._onSave.emit(objetoSavo)
      }));
    }
  }

  delete(id: string) {
    return this.http.delete(`${this.base_url}/api/licao/v1/${id}`)
  }

  removerImagem(licaoId: string, link: string) {
    return this.http.delete(`${this.base_url}/api/licao/galeria/${licaoId}`, {params: {link}})
  }

  ativarLicao(id: string) {
    return this.http.post(`${environment.apiUrl}/api/licao/v1/activate/${id}`, {});
  }

  desativarLicao(id: string) {
    return this.http.post(`${environment.apiUrl}/api/licao/v1/deactivate/${id}`, {});
  }
}
