import { DashboardModule } from './components/dashboard/dashboard.module';
import { HeaderComponent } from './components/header/header.component';
import { AlunoListModule } from './components/aluno-list/aluno-list.module';
import { ContasReceberModule } from './components/contas-receber/contas-receber.module';
import { CaixaModule } from './components/caixa/caixa.module';
import { CursoNivelNewModule } from './components/curso-nivel-new/curso-nivel-new.module';
import { CursoNewModule } from './components/curso-new/curso-new.module';
import { EstadosService } from './services/estado-br.service';

import { AlunoNewModule } from './components/aluno-new/aluno-new.module';
import { AlunoService } from './services/aluno.service';
import { TicketService } from './services/ticket.service';
import { AuthGuard } from './components/security/auth.guard';
import { AuthInterceptor } from './components/security/auth.interceptor';
import { SharedService } from './services/shared.service';
import { UserService } from './services/user.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';


import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/security/login/login.component';
import { routes } from './app.routes';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserNewComponent } from './components/user-new/user-new.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { DialogService } from '../dialog.service';
import { TicketNewComponent } from './components/ticket-new/ticket-new.component';
import { TicketListComponent } from './components/ticket-list/ticket-list.component';
import { TicketDetailComponent } from './components/ticket-detail/ticket-detail.component';
import { SummaryComponent } from './components/summary/summary.component';
import { CursoListModule } from './components/curso-list/curso-list.module';
import { CursoNivelListModule } from './components/curso-nivel-list/curso-nivel-list.module';
import { TurmaNewModule } from './components/turma-new/turma-new.module';
import { TurmaListModule } from './components/turma-list/turma-list.module';
import { MatriculaNewModule } from './components/matricula-new/matricula-new.module';
import { MatriculaListModule } from './components/matricula-list/matricula-list.module';
import { ReceberModule } from './components/receber/receber.module';
import { PagarModule } from './components/pagar/pagar.module';
import { CalendarioModule } from './components/calendario/calendario.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FrequenciaNewModule } from './components/frequencia-new/frequencia-new.module';
import { FrequenciaListModule } from './components/frequencia-list/frequencia-list.module';
import { MenuModule } from './components/menu/menu.module';
import { HomeModule } from './components/home/home.module';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import { DashboardNavModule } from './components/dashboard-nav/dashboard-nav.module';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgMaskModule } from '@fagnerlima/ng-mask';
import {
  MatSelectModule, MatOptionModule, MatButtonToggleModule,
  MatTooltipModule, MatToolbar, MatToolbarModule, MatDialog, MatDialogModule
} from '@angular/material';

import { LicaoNewModule } from './components/licao-new/licao-new.module';
import { LicaoListModule } from './components/licao-list/licao-list.module';

import { BibliaModule } from './components/biblia/biblia.module';
import { ResumoFrequenciaModule } from './relatorio/resumo-frequencia/resumo-frequencia.module';
import { CarregandoComponent } from './shared/Avisos/carregando/carregando.component';
import { BuscandoComponent } from './shared/Avisos/buscando/buscando.component';
import { RelatorioTurmaModule } from './relatorio/relatorio-turma/relatorio-turma.module';
import { MenuRelatorioModule } from './relatorio/menu-relatorio/menu-relatorio.module';
import { RelatorioFrequenciaAlunoModule } from './relatorio/relatorio-frequencia-aluno/relatorio-frequencia-aluno.module';
import { LicaoPrintModule } from './components/licao-print/licao-print.module';
import { ListagemLancamentoModule } from './components/lancamento/listagem-lancamento/listagem-lancamento.module';
import { BirthdayModule } from './components/birthday/birthday.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LicaoListagemModule } from './components/licao/licao-listagem/licao-listagem.module';
import { ComponentsModule } from './components/components.module';
import { AniversariantesModule } from './relatorio/aniversariantes/aniversariantes.module';
import { NotificationListModule } from './components/notification/notification-list/notification-list.module';



registerLocaleData(localeBr, 'pt');


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    UserNewComponent,
    UserListComponent,
    TicketNewComponent,
    TicketListComponent,
    TicketDetailComponent,
    SummaryComponent,
    CarregandoComponent,
    BuscandoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    routes,
    AlunoNewModule,
    CursoNewModule,
    CursoListModule,
    CursoNivelNewModule,
    CursoNivelListModule,
    TurmaNewModule,
    TurmaListModule,
    MatriculaNewModule,
    MatriculaListModule,
    CaixaModule,
    ReceberModule,
    PagarModule,
    ContasReceberModule,
    CalendarioModule,
    LicaoNewModule,
    LicaoListModule,
    NgbModule,
    AlunoListModule,
    FrequenciaNewModule,
    FrequenciaListModule,
    MenuModule,
    HomeModule,
    DashboardModule,
    DashboardNavModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatDialogModule,
    NgMaskModule,
    MatToolbarModule,
    BibliaModule,
    ResumoFrequenciaModule,
    RelatorioTurmaModule,
    MenuRelatorioModule,
    RelatorioFrequenciaAlunoModule,
    LicaoPrintModule,
    ListagemLancamentoModule,
    LicaoListagemModule,
    BirthdayModule,
    FlexLayoutModule,
    ComponentsModule,
    AniversariantesModule,
    NotificationListModule,
  ], entryComponents: [
    CarregandoComponent, BuscandoComponent
  ],
  providers: [
    UserService,
    SharedService,
    DialogService,
    TicketService,
    AlunoService,
    AuthGuard,
    EstadosService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'pt' }
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
