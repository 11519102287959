import { Router, ActivatedRoute } from "@angular/router";
import { AlunoService } from "./../../services/aluno.service";
import { DialogService } from "./../../../dialog.service";
import { Component, OnInit } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { ResponseApi } from "../../model/response-api";
import Swal from "sweetalert2";
import { Aluno } from "../../model/aluno.model";
import { Observable } from "rxjs";

@Component({
  selector: "app-aluno-list",
  templateUrl: "./aluno-list.component.html",
  styleUrls: ["./aluno-list.component.css"],
})
export class AlunoListComponent implements OnInit {
  page = 0;
  count = 20;
  pages: Array<number>;
  shared: SharedService;
  message: {};
  classCss: {};
  listAluno = [];
  isLoading = false;
  nomeAluno: string;
  alunos: Observable<Aluno[]>;

  constructor(
    private dialogService: DialogService,
    private alunoService: AlunoService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.findAll(this.page, this.count);
  }

  findAll(page: number, count: number) {
    this.isLoading = true;
    this.alunoService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        this.listAluno = responseApi["data"]["content"];
        this.pages = new Array(responseApi["data"]["totalPages"]);
        this.isLoading = false;
      },
      (err) => {
        this.showMessage({
          type: "error",
          text: err["error"]["errors"][0],
        });
      }
    );
  }

  pesquisar() {
    this.isLoading = true;
    if (this.nomeAluno) {
      this.alunoService
        .findByNomeContains(this.nomeAluno)
        .subscribe((responseApi: ResponseApi) => {
          this.listAluno = responseApi.data;
          this.isLoading = false;
        });
    } else {
      this.findAll(this.page, this.count);
      this.isLoading = false;
    }
  }

  /* findAllPesquisar(nomeAluno: String, page: number, count: number) {
    this.isLoading = true;
    this.alunoService.findAllPesquisar(nomeAluno, page, count).subscribe((responseApi: ResponseApi) => {
      this.listAluno = responseApi['data']['content'];
      this.pages = new Array(responseApi['data']['totalPages']);
      this.isLoading = false;
    }, err => {
      this.showMessage({
        type: 'error',
        text: err['error']['errors'][0]
      });
    });
  } */

  edit(id: string) {
    this.router.navigate(["/aluno-new", id]);
  }

  /* ativarAluno(id: string) {
    this.dialogService.confirm('Você deseja desativar este Aluno ?').then((podeDesativar: boolean) => {
      if (podeDesativar) {
        this.message = {};
        this.alunoService.ativarAluno(id).subscribe((responseApi: ResponseApi) => {
          console.log(responseApi);
          this.showMessage({
            type: 'success',
            text: 'Registro desativado'
          });
          this.findAll(this.page, this.count);
        }, err => {
          console.log(err);
          this.showMessage({
            type: 'error',
            text: err['error']['errors'][0]
          });
        });
      }
    });
  } */

  ativarAluno(id: string) {
    Swal.fire({
      title: "Tem certeza que deseja ativar este aluno?",
      text: "Você irá ativar!",
      type: "question",
      showCancelButton: true,

      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, ative!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.alunoService.ativarAluno(id).subscribe(
          () => {
            this.findAll(this.page, this.count);
          },
          (err) => {
            this.alertaError(err);
          }
        );
        Swal.fire(
          "Aluno ativado com sucesso!",
          "O aluno está ativado.",
          "success"
        );
      }
    });
  }

  desativarAluno(id: string) {
    Swal.fire({
      title: "Tem certeza que deseja inativar este aluno?",
      text: "Você irá inativar!",
      type: "warning",
      showCancelButton: true,

      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Ok!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.alunoService.desativarAluno(id).subscribe(
          () => {
            this.findAll(this.page, this.count);
          },
          (err) => {
            this.alertaError(err);
          }
        );
        Swal.fire("Aluno inativado com sucesso!", "Está inativado.", "success");
      }
    });
  }

  /* desativarAluno(id: string) {
    this.dialogService.confirm('Você deseja Ativar este Aluno ?').then((podeAtivar: boolean) => {
      if (podeAtivar) {
        this.message = {};
        this.alunoService.desativarAluno(id).subscribe((responseApi: ResponseApi) => {
          console.log(responseApi);
          this.showMessage({
            type: 'success',
            text: 'Registro Ativo'
          });
          this.findAll(this.page, this.count);
        }, err => {
          console.log(err);
          this.showMessage({
            type: 'error',
            text: err['error']['errors'][0]
          });
        });
      }
    });
  } */

  delete(id: string) {
    this.dialogService
      .confirm("Você deseja desativar este Aluno ?")
      .then((canDelete: boolean) => {
        if (canDelete) {
          this.message = {};
          this.alunoService.delete(id).subscribe(
            (responseApi: ResponseApi) => {
              this.showMessage({
                type: "success",
                text: "Registro desativado",
              });
              this.findAll(this.page, this.count);
            },
            (err) => {
              this.showMessage({
                type: "error",
                text: err["error"]["errors"][0],
              });
            }
          );
        }
      });
  }

  alertaError(error: string) {
    Swal.fire({
      type: "error",
      title: "Oops...",
      text: `Erro! "${error}"`,
    });
  }

  private showMessage(message: { type: string; text: string }): void {
    this.message = message;
    this.buildClasses(message.type);
    setTimeout(() => {
      this.message = undefined;
    }, 3000);
  }

  private buildClasses(type: string): void {
    this.classCss = {
      alert: true,
    };
    this.classCss["alert-" + type] = true;
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }

  onClickMigrate(aluno: Aluno) {
    Swal.fire({
      title: `Tem certeza que deseja migrar "${aluno.nome}" para usuário?`,
      text: "Você irá migrar este aluno!",
      type: "question",
      showCancelButton: true,

      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, migrar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.alunoService.migrateAlunoToUser(aluno).subscribe(
          () => {
            Swal.fire(
              "Aluno criado com sucesso!",
              "O aluno está criado.",
              "success"
            );
          },
          (err) => {
            this.alertaError(err);
          }
        );
      }
    });
  }
}
