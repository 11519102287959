import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { NotificationService } from "../../../services/notification.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-notification-detalhes",
  templateUrl: "./notification-detalhes.component.html",
  styleUrls: ["./notification-detalhes.component.scss"],
})
export class NotificationDetalhesComponent implements OnInit {

  @ViewChild('notificationForm') notificationForm: NgForm;

  notification = {
    id: null,
    title: "",
    content: "",
    image: null,
  };

  selectedFile: File = null;

  constructor(
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<NotificationDetalhesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.isEdit) {
      this.notification = { ...data.notification };
    }
  }

  ngOnInit() {}

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onSubmit() {
    if (this.notificationForm.valid) {
    const formData = new FormData();
    formData.append("title", this.notification.title);
    formData.append("content", this.notification.content);
    if (this.selectedFile) {
      formData.append("image", this.selectedFile, this.selectedFile.name);
    }

    if (this.data.isEdit) {
      this.notificationService
        .updateNotification(this.notification.id, formData)
        .subscribe(
          (response) => {
            console.log("Notificação atualizada com sucesso!", response);
            this.dialogRef.close(true);
          },
          (error) => {
            console.error("Erro ao atualizar notificação", error);
          }
        );
    } else {
      this.notificationService.sendNotification(formData).subscribe(
        (response) => {
          console.log("Notificação enviada com sucesso!", response);
        },
        (error) => {
          console.error("Erro ao enviar notificação", error);
        }
      );
    }
    }

  }

  onCancel() {
    this.dialogRef.close();
  }
}
