import { ResponseApi } from "../../model/response-api";
import { UserService } from "../../services/user.service";
import { SharedService } from "../../services/shared.service";
import { Component, OnInit } from "@angular/core";
import { DialogService } from "../../../dialog.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit {
  page: number = 0;
  count: number = 20;
  pages: Array<number>;
  shared: SharedService;
  message: {};
  classCss: {};
  listUser = [];
  isLoading = false;
  email: string;
  acesso: string;
  constructor(private userService: UserService, private router: Router) {
    this.shared = SharedService.getInstance();
    // console.log(this.shared);
  }

  ngOnInit() {
    this.findAll(this.page, this.count);
    this.acesso = this.shared.user.profile;
    // console.log(this.acesso);
  }

  findAll(page: number, count: number) {
    this.isLoading = true;
    this.userService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        this.listUser = responseApi["data"]["content"];
        this.pages = new Array(responseApi["data"]["totalPages"]);
        this.isLoading = false;
      },
      (err) => {
        this.showMessage({
          type: "error",
          text: err["error"]["errors"][0],
        });
      }
    );
  }

  pesquisar() {
    this.isLoading = true;
    if (this.email) {
      this.userService
        .findByEmailContains(this.email)
        .subscribe((responseApi: ResponseApi) => {
          console.log(responseApi);
          this.listUser = responseApi.data;
          this.isLoading = false;
        });
    } else {
      this.findAll(this.page, this.count);
      this.isLoading = false;
    }
  }

  edit(id: string) {
    this.router.navigate(["/user-new", id]);
  }

  ativarUsuario(id: string) {
    Swal.fire({
      title: "Tem certeza que deseja ativar este usuário?",
      text: "Você irá ativar!",
      type: "question",
      showCancelButton: true,

      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, ative!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.userService.ativarUsuario(id).subscribe(
          () => {
            this.findAll(this.page, this.count);
          },
          (err) => {
            this.alertaError();
          }
        );
        Swal.fire(
          "Usuário ativado com sucesso!",
          "Usuário ativado.",
          "success"
        );
      }
    });
  }

  desativarUsuario(id: string) {
    Swal.fire({
      title: "Tem certeza que deseja inativar este usuário?",
      text: "Você irá inativar!",
      type: "warning",
      showCancelButton: true,

      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Ok!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.userService.desativarUsuario(id).subscribe(
          () => {
            this.findAll(this.page, this.count);
          },
          (err) => {
            this.alertaError();
          }
        );
        Swal.fire(
          "Usuário inativado com sucesso!",
          "Usuário inativado.",
          "success"
        );
      }
    });
  }

  delete(id: string) {
    Swal.fire({
      title: "Você tem certeza??",
      text: "Você irá inativar!",
      type: "warning",
      showCancelButton: true,

      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Ok!",
    }).then((result) => {
      if (result.value) {
        this.userService.delete(id).subscribe(
          () => {
            this.findAll(this.page, this.count);
          },
          (err) => {
            this.alertaError();
          }
        );
        Swal.fire("Desativado!", "Aluno desativado.", "success");
      }
    });
  }

  alertaError() {
    Swal.fire({
      type: "error",
      title: "Oops...",
      text: "O servidor encontrou um erro. Entre em contato com o suporte.",
    });
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();

    this.page = i;
    this.findAll(this.page, this.count);
  }

  private showMessage(message: { type: string; text: string }): void {
    this.message = message;
    this.buildClasses(message.type);
    setTimeout(() => {
      this.message = undefined;
    }, 3000);
  }

  private buildClasses(type: string): void {
    this.classCss = {
      alert: true,
    };
    this.classCss["alert-" + type] = true;
  }

  onDownloadPdf() {
    this.userService.downloadUsersPdf().subscribe({
      next: (blob: Blob) => {
        // Criar URL para o blob
        const url = window.URL.createObjectURL(blob);
        // Criar um link <a> e acionar clique
        const link = document.createElement("a");
        link.href = url;
        link.download = "usuarios.pdf"; // nome do arquivo
        link.click();
        // Liberar o objeto URL
        window.URL.revokeObjectURL(url);
      },
      error: (err) => {
        console.error("Erro ao baixar PDF", err);
      },
    });
  }

  onGeneratePdf(userId: string) {
    this.userService.downloadUserPdf(userId).subscribe({
      next: (pdfBlob) => {
        const fileURL = URL.createObjectURL(pdfBlob);
        // abrir numa nova aba:
        window.open(fileURL, "_blank");
      },
      error: (err) => {
        console.error(err);
        alert("Falha ao gerar PDF do usuário");
      },
    });
  }

  onCopyQrCode(qrCodeToken: string) {
    // Usa a API do navegador p/ copiar
    if (!qrCodeToken) {
      alert("Esse usuário não tem qrCodeToken");
      return;
    }

    const input = document.createElement("input");
    input.style.position = "fixed";
    input.style.opacity = "0";
    input.value = qrCodeToken;
    document.body.appendChild(input);

    // Seleciona e copia
    input.select();
    document.execCommand("copy");

    // Remove o input
    document.body.removeChild(input);

    alert("QR code copiado com sucesso!");
  }
}
