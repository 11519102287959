import { Response } from './../model/respose.model';
import { Aluno } from './../model/aluno.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../environments/environment';


@Injectable()
export class AlunoService {
  constructor(private http: HttpClient) {}

  createOrUpdate(aluno: Aluno) {
    if (aluno.id != null && aluno.id !== "") {
      return this.http.put(`${environment.apiUrl}/api/aluno`, aluno);
    } else {
      aluno.id = null;
      return this.http.post(`${environment.apiUrl}/api/aluno`, aluno);
    }
  }

  findBirthDay() {
    return this.http.get<Response>(
      `${environment.apiUrl}/api/aluno/aniversario`
    );
  }
  aniversariantes(dateInitial: Date, dateFinal: Date) {
    const params = {};

    if (dateInitial) {
      params["dateInitial"] = this.formatDate(dateInitial);
    }
    if (dateFinal) {
      params["dateFinal"] = this.formatDate(dateFinal);
    }
    return this.http.get<Response>(
      `${environment.apiUrl}/api/aluno/aniversariantes`,
      { params }
    );
  }

  findAll(page: number, count: number) {
    return this.http.get<Response>(
      `${environment.apiUrl}/api/aluno/${page}/${count}`
    );
  }

  findAllAlunos() {
    return this.http.get<Response>(`${environment.apiUrl}/api/aluno/`);
  }

  findByNomeContains(nomeAluno: String) {
    return this.http.get<Response>(
      `${environment.apiUrl}/api/aluno/byName/${nomeAluno}`
    );
  }

  /* findAllPesquisar(nomeAluno: String, page: number, count: number) {
    return this.http.get<Response>(`${environment.apiUrl}/api/aluno/pesquisar/${nomeAluno}/${page}/${count}`);
  } */

  pesquisar(filtro: any) {
    const params = new URLSearchParams();
    if (filtro.nomeAluno) {
      // params.set('nomeAluno', filtro.nomeAluno);
      return this.http.get<Response>(
        `${environment.apiUrl}/api/aluno/byName/${filtro.nomeAluno}`
      );
    }
    if (filtro.cpfAluno) {
      return this.http.get<Response>(
        `${environment.apiUrl}/api/aluno/byName/${filtro.cpfAluno}`
      );
    }
  }

  findByCpfAluno(cpfAluno: string) {
    return this.http.get<Response>(
      `${environment.apiUrl}/api/aluno/byName/${cpfAluno}`
    );
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/aluno/${id}`);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/aluno/${id}`);
  }

  ativarAluno(id: string) {
    return this.http.post(`${environment.apiUrl}/api/aluno/ativar`, id);
  }

  desativarAluno(desativar: string) {
    return this.http.post(
      `${environment.apiUrl}/api/aluno/desativar`,
      desativar
    );
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // +1 porque os meses começam de 0
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  alunosFaltosos(isProfessor: Boolean) {
    return this.http.get<Response>(
      `${environment.apiUrl}/api/aluno/faltosos/${isProfessor}`
    );
  }

  migrateAlunoToUser(aluno: Aluno) {
    return this.http.post(`${environment.apiUrl}/api/aluno/migrate`, aluno, {
      responseType: "text",
    });
  }
}
