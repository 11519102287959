export class QuizzQuestion {
  enunciado: string = '';
  alternativas: string[] = [];
  correctIndex: number = 0;
  points: number = 0;
}

export class Quizz {
  active: boolean = false;
  startDate: Date = null;
  endDate: Date = null;
  questions: QuizzQuestion[] = [
    new QuizzQuestion(),
    new QuizzQuestion(),
    new QuizzQuestion(),
  ]
}
