<h1 mat-dialog-title>
  {{ data.isEdit ? "Editar Notificação" : "Criar Notificação" }}
</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #notificationForm="ngForm">
    <mat-card>
      <mat-card-title>Criar Notificação</mat-card-title>
      <mat-card-content>
        <!-- Campo para o título -->
        <mat-form-field appearance="fill">
          <mat-label>Título</mat-label>
          <input
            matInput
            name="title"
            [(ngModel)]="notification.title"
            required
          />
          <mat-error *ngIf="notificationForm.submitted && notificationForm.controls.content.invalid">
            O título é obrigatório.
          </mat-error>
        </mat-form-field>

        <!-- Campo para o conteúdo -->
        <mat-form-field appearance="fill">
          <mat-label>Conteúdo</mat-label>
          <textarea
            matInput
            name="content"
            [(ngModel)]="notification.content"
            required
          ></textarea>
           <mat-error *ngIf="notificationForm.submitted && notificationForm.controls.content.invalid">
            O conteúdo é obrigatório.
          </mat-error>
        </mat-form-field>

        <!-- Upload de imagem -->
        <div>
          <label for="image">Imagem:</label>
          <input
            type="file"
            (change)="onFileSelected($event)"
            accept="image/*"
          />
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" type="submit">Enviar</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancelar</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">Salvar</button>
</div>
