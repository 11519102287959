import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PageNotification } from '../model/notification.model';

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private base_url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  sendNotification(data: FormData) {
    return this.http.post(`${this.base_url}/api/notifications`, data);
  }

  getNotification(page: number, pageSize: number, recurrence?: string) {
    const params:any = {
      page: page.toString(),
      size: pageSize.toString()
    };
    if (recurrence && recurrence !== '') {
      params.recurrence = recurrence;
    }
    return this.http.get<PageNotification>(
      `${this.base_url}/api/notifications`,
      { params }
    );
  }

  updateNotification(id: string, data: any) {
    return this.http.put(`${this.base_url}/${id}`, data);
  }

  deleteNotification(id: string) {
    return this.http.delete(`${this.base_url}/${id}`);
  }
}
