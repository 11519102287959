<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{ cardAluno: true }">
            <mat-card-title style="color: white">Novo Usuário</mat-card-title>
          </mat-card>
        </div>
        <form
          *ngIf="form"
          [formGroup]="form"
          class="form horizontal"
          (ngSubmit)="register()"
          #f="ngForm"
        >
          <div class="image">
            <app-input-image formControlName="imagem"></app-input-image>
          </div>
          <div class="row">
            <div class="form-group">
              <div class="col-sm-4">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="email">E-mail</mat-label>
                  <input
                    matInput
                    placeholder="email"
                    id="email"
                    formControlName="email"
                    required
                    email
                  />
                  <mat-error>E-mail é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-4">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="password">Senha</mat-label>
                  <input
                    matInput
                    placeholder="Senha"
                    id="password"
                    formControlName="password"
                    [type]="hide ? 'password' : 'text'"
                    required
                  />
                  <mat-icon matSuffix (click)="hide = !hide">{{
                    hide ? "visibility_off" : "visibility"
                  }}</mat-icon>
                  <mat-error>Senha é obrigatória</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-4">
                <mat-form-field
                  appearance="outline"
                  class="example-full-width"
                  class="col-sm-12"
                >
                  <mat-label for="profile">Perfil</mat-label>
                  <mat-select
                    placeholder="Perfil"
                    id="profile"
                    formControlName="profile"
                    required
                  >
                    <mat-option value="ROLE_ADMIN">ADMIN</mat-option>
                    <mat-option value="ROLE_PROFESSOR">PROFESSOR</mat-option>
                    <mat-option value="ROLE_DIRETOR">DIRETOR</mat-option>
                    <mat-option value="ROLE_PASTOR">PASTOR</mat-option>
                    <mat-option value="ROLE_ALUNO">ALUNO</mat-option>
                  </mat-select>
                  <mat-error>Perfil é obrigatório</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <div class="col-sm-4">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="nome">Nome</mat-label>
                  <input
                    matInput
                    placeholder="Nome"
                    id="nome"
                    formControlName="nome"
                  />
                  <mat-error>Nome é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-4">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="telefone">Telefone</mat-label>
                  <input
                    matInput
                    placeholder="Telefone"
                    id="telefone"
                    formControlName="telefone"
                    [mask]="maskPhone"
                  />
                  <mat-error>Telefone é obrigatório</mat-error>
                </mat-form-field>
              </div>
              <mat-progress-spinner
                *ngIf="isLoadingTurma"
                mode="indeterminate"
                color="primary"
              ></mat-progress-spinner>
              <div *ngIf="hasTurma && !isLoadingTurma" class="col-sm-4">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="turmaId">Turma</mat-label>
                  <mat-select
                    matInput
                    placeholder="Seleciona uma turma"
                    id="turmaId"
                    formControlName="turmaId"
                    >
                    <mat-option
                      *ngFor="let turma of turmas | async"
                      [value]="turma.id"
                    >
                      {{ turma?.nome }}
                    </mat-option>
                  </mat-select>
                  <mat-error>Turma é obrigatório</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="container-fluid">
            <div class="buttons">
              <button mat-raised-button color="warn">Salvar</button>
            </div>
            <div class="listas">
              <button
                mat-raised-button
                color="primary"
                type="button"
                (click)="listaUsuarios()"
              >
                Lista de Usuários
              </button>
            </div>
          </div>

          <!-- <app-form-debug [formularioDebug]="form"> </app-form-debug> -->
        </form>

        <div class="box box-danger"></div>
      </div>
    </div>
    <div *ngIf="isLoading" class="spinner" text-center>
      <mat-progress-bar mode="indeterminate" color="warn"
        >Carregando...</mat-progress-bar
      >
    </div>
  </div>
</div>
