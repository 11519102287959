import { LicaoV1Service } from "./../../../services/licaov1.service";
import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from "@angular/forms";
import { SharedService } from "../../../services/shared.service";
import { Turma } from "../../../model/turma.model";
import { Observable, forkJoin, of } from "rxjs";
import { User } from "../../../model/user.model";
import { ActivatedRoute, Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { LicaoService } from "../../../services/licao.service";
import { TurmaService } from "../../../services/turma.service";
import { UserService } from "../../../services/user.service";
import * as DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import {
  concatMap,
  debounceTime,
  finalize,
  first,
  map,
  takeWhile,
} from "rxjs/operators";
import { UtilitariosService } from "../../../services/utilitario.service";
import { ImagePreview } from "../../lancamento/images-lancamento/images-lancamento.component";
import {
  LicaoEntity,
  LicaoEntityResponse,
} from "../../../model/licao_entity.model";
import { DatePipe } from "@angular/common";
import { Arquivo } from "../../../model/arquivo.model";
import * as moment from "moment";

import Swal from "sweetalert2";
import { CarregandoComponent } from "../../../shared/Avisos/carregando/carregando.component";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Quizz } from "../../../model/quizz.model";
import { ImageMetadata } from "../../../model/image_metadata.model";

@Component({
  selector: "app-licao-detalhes",
  templateUrl: "./licao-detalhes.component.html",
  styleUrls: ["./licao-detalhes.component.scss"],
})
export class LicaoDetalhesComponent implements OnInit {
  public Editor = DecoupledEditor;
  public editorContent = "";
  @ViewChild("f")
  f: NgForm;
  form: FormGroup;

  shared: SharedService;
  turmas: Observable<Turma[]>;
  selectedTurma: Turma;
  professores: Observable<User[]> | null = null;
  selectedProfessor: User;

  carregando = false;
  ativo = true;
  id: string = null;
  licao: LicaoEntity;
  hasProfessor: boolean = false;
  private base_url = environment.apiUrl;
  public editorConfig;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private turmaService: TurmaService,
    private licaoService: LicaoV1Service,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private router: Router,
    private utils: UtilitariosService,
    private appRef: ApplicationRef,
    public datepipe: DatePipe,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.shared = SharedService.getInstance();
  }

  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
    editor.model.document.on("change:data", () => {
      this.editorContent = editor.getData();
    });
  }

  ngOnInit() {
    const hoje = new Date();
    hoje.setHours(12, 0, 0, 0);

    this.editorConfig = {
      placeholder: "Digite aqui o conteúdo planejado...",
      toolbar: {
        items: [
          "undo",
          "redo",
          "heading",
          "|",
          "fontSize",
          "fontFamily",
          "|",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "link",
          "blockQuote",
          "insertTable",
          "imageUpload",
          "mediaEmbed",
          "|",
          "alignment",
          "|",
          "numberedList",
          "bulletedList",
          "|",
          "outdent",
          "indent",
          "|",
          "removeFormat",
        ],
        shouldNotGroupWhenFull: true,
      },
      fontFamily: {
        options: [
          "default",
          "Montserrat, sans-serif",
          "Arial, Helvetica, sans-serif",
          "Courier New, Courier, monospace",
          "Georgia, serif",
          "Tahoma, Geneva, sans-serif",
          "Times New Roman, Times, serif",
          "Trebuchet MS, Helvetica, sans-serif",
          "Verdana, Geneva, sans-serif",
        ],
      },
      // Outras configurações (heading, link, table, image, language, etc.)
      language: "pt-br",
    };

    this.carregando = true;
    this.form = this.formBuilder.group({
      id: [null],
      data: [hoje, Validators.required],
      turma: [null, Validators.required],
      professor: [null, Validators.required],
      tema: [null, Validators.required],
      objetivo: [null, Validators.required],
      conteudo: [null, Validators.required],
      metodologia: [null, Validators.required],
      experienciaPedagogica: [null],
      imagens: [null],
      status: [null],
      quizz: this.formBuilder.group({
        active: [true],
        startDate: [new Date()], // p/ data de início
        endDate: [new Date()], // p/ data de fim
        // array de 3 perguntas
        questions: this.formBuilder.array([
          this.createQuestionGroup(),
          this.createQuestionGroup(),
          this.createQuestionGroup(),
        ]),
      }),
    });

    if (this.shared.user.profile == "PROFESSOR") {
      const professorSelecionado = this.shared.user;

      this.turmas = this.turmaService
        .findById(professorSelecionado.turmaId)
        .pipe(
          map((turmas) => {
            if (turmas.data && turmas.data.status === false) {
              return [turmas.data];
            } else {
              return [];
            }
          })
        );

      this.turmas
        .pipe(
          map((turmas) => {
            if (!professorSelecionado || !professorSelecionado.turmaId) {
              return null;
            }
            return turmas.find(
              (turma) => turma.id == professorSelecionado.turmaId
            );
          }),
          first()
        )
        .subscribe((turmaSelecionada) => {
          if (turmaSelecionada) {
            this.selectedTurma = turmaSelecionada;
            this.form.get("turma").setValue(this.selectedTurma);
            if (!this.data) {
              if (this.professores == null) {
                this.professores = this.addProfessor(professorSelecionado);
              }
              this.setProfessor(professorSelecionado);
            }
          }
          this.carregando = false;
        });
    } else {
      this.turmas = this.turmaService.findAllTurma().pipe(
        map((turmas) => {
          return turmas.data.filter((turma) => turma.status === false);
        }),
        finalize(() => {
          this.carregando = false;
        })
      );
    }

    // if (!this.data) {
    //   this.carregando = true;
    //   if (this.utils.getDadosLocalStorage(this.constructor.name)) {
    //     const dadosRecuperados = this.utils.getDadosLocalStorage(
    //       this.constructor.name
    //     );
    //     this.utils.preencherFormGroup(this.form, dadosRecuperados);
    //     this.form.get("id").setValue(null);
    //     if (dadosRecuperados.data == null) {
    //       this.form.get("data").setValue(new Date());
    //     }
    //     if (dadosRecuperados.turma) {
    //       this.onTurmaSelected(dadosRecuperados.turma);
    //     } else {
    //       this.carregando = false;
    //     }
    //   } else {
    //     this.carregando = false;
    //   }
    // }

    if (this.data) {
      this.carregando = true;
      forkJoin([this.licaoService.findById(this.data, "")])
        .pipe(takeWhile(() => this.ativo))
        .subscribe(
          ([licao]) => {
            const dateFromBackend = new Date(licao.data);
            const correctedDate = new Date(
              dateFromBackend.getUTCFullYear(),
              dateFromBackend.getUTCMonth(),
              dateFromBackend.getUTCDate()
            );
            // this.alunos = alunos
            this.form.get("id").setValue(licao.id);
            this.form.get("data").setValue(correctedDate);
            this.form.get("turma").setValue(licao.turma);
            // this.form.get("professor").setValue(licao.professor);
            this.form.get("tema").setValue(licao.tema);
            this.form.get("objetivo").setValue(licao.objetivo);
            this.form.get("conteudo").setValue(licao.conteudo);
            this.form.get("metodologia").setValue(licao.metodologia);
            this.form
              .get("experienciaPedagogica")
              .setValue(licao.experienciaPedagogica);

            //quizz
            if (licao.quizz) {
              this.form.get("quizz.active").setValue(licao.quizz.active);
              this.form.get("quizz.startDate").setValue(licao.quizz.startDate);
              this.form.get("quizz.endDate").setValue(licao.quizz.endDate);
              if (licao.quizz.questions && licao.quizz.questions.length === 3) {
                const questionsArray = this.form.get(
                  "quizz.questions"
                ) as FormArray;

                for (let i = 0; i < 3; i++) {
                  questionsArray
                    .at(i)
                    .get("enunciado")
                    .setValue(licao.quizz.questions[i].enunciado);
                  questionsArray
                    .at(i)
                    .get("correctIndex")
                    .setValue(licao.quizz.questions[i].correctIndex);
                  questionsArray
                    .at(i)
                    .get("points")
                    .setValue(licao.quizz.questions[i].points);

                  // Alternativas
                  const altArray = questionsArray
                    .at(i)
                    .get("alternativas") as FormArray;

                  licao.quizz.questions[i].alternativas.forEach(
                    (alt, altIndex) => {
                      altArray.at(altIndex).setValue(alt);
                    }
                  );
                }
              }
            }

            this.form.get("status").setValue(licao.status);
            licao.imagens.forEach((imagem, index) => {
              this.urlToFile(imagem, "image_" + (index + 1)).then((file) => {
                const imagens: ImagePreview[] =
                  this.form.get("imagens").value || [];
                const urlLink = this.objectToString(imagem);
                imagens.push({ file, preview: urlLink.toString() });
                this.form.get("imagens").setValue(imagens);
              });
            });
            if (this.professores == null) {
              this.professores = this.addProfessor(licao.professor);
            }
            this.setProfessor(licao.professor);
            this.appRef.tick();
            const interval = setInterval(() => {
              const imagens: ImagePreview[] =
                this.form.get("imagens").value || [];
              if (licao.imagens.length === imagens.length) {
                this.carregando = false;
                clearInterval(interval);
              }
            }, 300);
            this.carregando = false;
          },
          (err) => {
            this.carregando = false;
            throw err;
          }
        );
    }
    //  else {
    //   this.form.valueChanges
    //     .pipe(
    //       takeWhile(() => this.ativo),
    //       debounceTime(2000)
    //     )
    //     .subscribe((value) => {
    //       if (!this.id) {
    //         this.utils.setDadosLocalStorage(this.constructor.name, value);
    //       }
    //     });
    // }
  }

  private setProfessor(professorSelecionado: User) {
    this.selectedProfessor = {
      id: professorSelecionado.id,
      email: professorSelecionado.email,
      password: professorSelecionado.password,
      profile: professorSelecionado.profile,
      nome: professorSelecionado.nome,
      telefone: professorSelecionado.telefone,
      imagem: professorSelecionado.imagem,
      status: professorSelecionado.status,
      turmaId: professorSelecionado.turmaId,
    };

    this.form.get("professor").setValue(this.selectedProfessor);
  }

  async salvar() {
    if (this.form.valid) {
      this.abrirConfirmacao();
      this.carregando = true;
      const formValue = this.form.value;
      const licao = this.licao || new LicaoEntity();
      licao.id = this.form.get("id").value;
      licao.data = this.stringToDate(this.form.get("data").value);
      licao.turma = this.form.get("turma").value;
      licao.professor = this.form.get("professor").value;
      licao.tema = this.form.get("tema").value;
      licao.objetivo = this.form.get("objetivo").value;
      licao.conteudo = this.form.get("conteudo").value;
      licao.metodologia = this.form.get("metodologia").value;
      licao.experienciaPedagogica = this.form.get(
        "experienciaPedagogica"
      ).value;
      licao.status = false;
      const images: ImagePreview[] = this.form.get("imagens").value;
      if (images && images.length > 0) {
        const arquivos: Arquivo<any>[] = [];
        for (const imagem of images) {
          if (imagem && imagem.preview.includes("base64")) {
            arquivos.push(Arquivo.createFile(imagem.file));
          } else if (imagem && imagem.preview) {
            arquivos.push(Arquivo.createString(imagem.preview));
          }
        }
        licao.imagens = arquivos;
      }

      //quizz
      licao.quizz.active = this.form.get("quizz.active").value;
      // licao.quizz.startDate = this.form.get("quizz.startDate").value;
      // licao.quizz.endDate = this.form.get("quizz.endDate").value;
      if (formValue.quizz.startDate) {
        const start =
          formValue.quizz.startDate instanceof Date
            ? formValue.quizz.startDate
            : new Date(formValue.quizz.startDate);
        licao.quizz.startDate = start.toISOString();
      }
      if (formValue.quizz.endDate) {
        const end =
          formValue.quizz.endDate instanceof Date
            ? formValue.quizz.endDate
            : new Date(formValue.quizz.endDate);
        licao.quizz.endDate = end.toISOString();
      }
      const questionsFA = this.form.get("quizz.questions") as FormArray;
      licao.quizz.questions = questionsFA.value; // array de 3 QuizzQuestion

      this.licaoService
        .save(licao)
        .pipe(takeWhile(() => this.ativo))
        .subscribe(
          (response: LicaoEntityResponse) => {
            this.dialog.closeAll();
            this.carregando = false;
            this.alertaSucesso();
            if (this.form.get("id").value) {
              this.voltar();
            } else {
              this.reset();
            }
            return response;
          },
          (err) => {
            this.carregando = false;
            try {
              this.alertaError();
            } catch (error) {
              //erro
            }
          }
        );
    } else {
      this.form.markAsTouched();
    }
  }

  voltar() {
    this.dialog.closeAll();
  }

  adicionarImagens(imagens: { link: string }[]) {
    imagens.forEach((imagem, index) => {
      const images: ImagePreview[] = this.form.get("imagens").value || [];
      images.push({ file: null, preview: imagem.link });
      this.form.get("imagens").setValue(images);
    });
  }

  compareProfessor(professor1: User, professor2: User): boolean {
    return professor1 && professor2
      ? professor1.id === professor2.id
      : professor1 === professor2;
  }

  compareTurma(turma1: Turma, turma2: Turma): boolean {
    return turma1 && turma2 ? turma1.id === turma2.id : turma1 === turma2;
  }

  onTurmaSelected(turma: Turma) {
    const isProfessor = this.shared.user.profile == "PROFESSOR";
    let turmaId = isProfessor ? this.shared.user.turmaId : turma.id;
    this.professores = this.userService
      .findByTurmaIdContainsAndProfileNot(turmaId)
      .pipe(
        map((users) => {
          if (users && users.length > 0) this.hasProfessor = true;
          if (isProfessor) {
            let filteredUser = users.filter(
              (user) => user.id === this.shared.user.id
            );
            this.setProfessor(filteredUser[0]);
          } else {
            this.setProfessor(users[0]);
          }
          this.carregando = false;
          return users;
        })
      );
  }

  deleteImage({ preview }: ImagePreview) {
    if (!preview.includes("base64")) {
      this.carregando = true;
      this.licaoService.removerImagem(this.data, preview).subscribe(
        () => {
          this.carregando = false;
        },
        (e) => {
          this.carregando = false;
          throw e;
        }
      );
    }
  }

  hasImageToSave() {
    const images: ImagePreview[] = this.form.get("imagens").value || [];
    const newImageFiles = images
      .filter((image) => image.file && image.preview.includes("base64"))
      .map((image) => image.file);
    return newImageFiles.length > 0;
  }

  async urlToFile(url: string, fileName: string) {
    const urlLink = this.objectToString(url);
    let response = await fetch(urlLink.toString());
    let data = await response.blob();
    return new File([data], fileName);
  }

  private objectToString(imagem: string) {
    return imagem.link;
  }

  stringToDate(value) {
    if (!value) return null;

    let convertDate = this.datepipe.transform(value, "dd/MM/yyyy");
    let dataString = "";
    const itens = convertDate.split(" ");
    let data: string[] = null;
    if (itens && itens.length > 0) {
      data = itens[0].split("/");
      if (data.length < 3) return null;
      else dataString = data[2] + "-" + data[1] + "-" + data[0];
    }
    if (itens && itens.length > 1) dataString += " " + itens[1];
    if (!moment(new Date(dataString)).isValid()) return null;
    return moment(new Date(dataString)).toDate();
  }

  customUploadAdapterPlugin = (editor: any) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any
    ) => {
      return {
        upload: () => {
          return loader.file.then((file: File) => {
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              formData.append("file", file);
              return this.http
                .post<String>(
                  `${this.base_url}/api/upload/upload-image`,
                  formData,
                  { responseType: "text" as "json" }
                )
                .subscribe(
                  (link) => {
                    resolve({ default: link });
                  },
                  (error) => {
                    console.error("Erro no upload:", error);
                    reject("Falha no upload");
                  }
                );
            }).catch((error) => {
              console.error("Erro na promessa:", error);
            });
          });
        },
      };
    };
  };

  reset() {
    this.form.reset();
    this.form.markAsUntouched();
    this.form.markAsPending();
    this.form.markAsPristine();
    this.carregando = false;
    this.utils.removeDadosLocalStorage(this.constructor.name);
  }

  abrirConfirmacao() {
    this.dialog.open(CarregandoComponent, {
      width: "350px",
    });
  }

  alertaSucesso() {
    Swal.fire({
      title: "Lição salva com sucesso!",
      type: "success",
      footer: "Igreja Batista Nacional Ebenézer",
    });
  }

  alertaError() {
    Swal.fire({
      type: "error",
      title: "Oops...",
      html: "O servidor encontrou um possível erro, verifique:<br> 1. Imagem maior que 5MB.<br> 2. Problema na conexão.",
    });
  }

  addProfessor(licaoProfessor: User): Observable<User[]> {
    return this.ensureProfessoresObservable().pipe(
      concatMap((users) => {
        const updatedUsers = [...users, licaoProfessor];
        return of(updatedUsers);
      })
    );
  }

  ensureProfessoresObservable(): Observable<User[]> {
    if (!this.professores) {
      this.professores = of([]);
    }
    return this.professores;
  }

  createQuestionGroup(): FormGroup {
    return this.formBuilder.group({
      //TODO: VOLTAR PARA REQUIRED
      // enunciado: ["", Validators.required],
      enunciado: [""],
      alternativas: this.formBuilder.array([
        // array de string
        this.formBuilder.control(""),
        this.formBuilder.control(""),
        this.formBuilder.control(""),
        this.formBuilder.control(""),
      ]),
      correctIndex: [0],
      points: [{ value: 3, disabled: true }],
    });
  }

  get questionsArray(): FormArray {
    return this.form.get("quizz.questions") as FormArray;
  }

  getAlternativas(questionIndex: number): FormArray {
    return this.questionsArray
      .at(questionIndex)
      .get("alternativas") as FormArray;
  }

  onImageSelected(selectedImage: ImageMetadata): void {
    const currentImages: any[] = this.form.get("imagens").value || [];

    currentImages.push({
      file: null, // Como não faremos upload, pode ser nulo ou um marcador
      preview: selectedImage.mediaLink, // Ou thumbnailUrl, conforme desejado
    });
    this.form.get("imagens").setValue(currentImages);
  }
}
